import {graphql, useStaticQuery} from "gatsby";
import React from "react";

import StructuredData, {
  StructuredDataType,
} from "../../components/StructuredData";
import BlogGallery from "./BlogGallery/BlogGallery";

const NewPosts = () => {
  const data = useStaticQuery(graphql`
    {
      sanityPresspage {
        posts {
          author_dp {
            asset {
              gatsbyImageData
            }
          }
          authorAlt
          author
          post_tag
          title
          url
          img {
            asset {
              gatsbyImageData
            }
          }
          alt
          featuredhome
        }
      }
      allGhostPost(sort: {fields: published_at, order: DESC}) {
        nodes {
          feature_image
          og_image
          localImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          featured
          slug
          tags {
            name
            slug
          }
          title
          authors {
            profile_image
            name
            url
          }
          published_at
          updated_at
        }
      }
    }
  `);

  return (
    <>
      <StructuredData
        type={StructuredDataType.BLOGS}
        data={{
          url: "https://getmason.io/blog",
          name: "Discover the tech powering modern storefronts",
          description:
            "Case studies, user guides, product teardowns, and expert insights to take you from startup to scale-up. Subscribe to get the latest updates.",
          breadcrumbList: {
            items: data.allGhostPost.nodes.map((blog) => ({
              name: blog.title,
              item: `https://getmason.io/blog/post/${blog.slug}`,
            })),
          },
        }}
      />
      <div className='new-posts-wrap'>
        <h1
          className='light'
          style={{
            textAlign: "center",
            fontSize: "var(--XL)",
            lineHeight: "inherit",
            letterSpacing: "-.8px",
          }}
        >
          Read noteworthy posts
        </h1>
        <br />
        <br />
        <BlogGallery data={data.allGhostPost.nodes} post_type='home' />
      </div>
    </>
  );
};

export default NewPosts;
