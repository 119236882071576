import {Link, graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import React, {useState} from "react";
import ArrowLeft from "react-feather/dist/icons/arrow-left";
import ArrowRight from "react-feather/dist/icons/arrow-right";

import "./FeaturedBlogs.scss";

const FeaturedBlogs = () => {
  const data = useStaticQuery(graphql`
    {
      allGhostPost(filter: {featured: {eq: true}}) {
        edges {
          node {
            localImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            featured
            slug
            tags {
              name
              slug
            }
            title
            authors {
              profile_image
              name
            }
          }
        }
      }
    }
  `);
  const [index, setIndex] = useState(0);
  return (
    <div className='f-blog-wrap my-24 relative'>
      <div className='nav-btns flex flex-row'>
        <div
          className='prev-btn'
          onClick={() => {
            index === 0
              ? setIndex(data.allGhostPost.edges.length - 1)
              : setIndex(index - 1);
          }}
        >
          <ArrowLeft />
        </div>
        <div
          className='next-btn'
          onClick={() => {
            index === data.allGhostPost.edges.length - 1
              ? setIndex(0)
              : setIndex(index + 1);
          }}
        >
          <ArrowRight />
        </div>
      </div>
      {data.allGhostPost.edges.map((post, i) => (
        <div
          className={`${
            index === i ? "showing-post" : "hidden"
          } f-post shadow-md flex flex-col md:flex-row rounded-lg bg-white h-full`}
        >
          <div className='f-display md:w-4/6'>
            <GatsbyImage
              image={post.node?.localImage.childImageSharp.gatsbyImageData}
              alt={post.node.slug}
            />
            {/* <img src={post.node.feature_image} alt="image" /> */}
          </div>
          <div className='f-content p-8 md:w-2/6 flex flex-col justify-evenly items-start'>
            {post.node.tags
              .filter(({name}) => !name.startsWith("#"))
              .map((tag) => (
                <Link to={`/blog/tag/${tag.slug}`} className='post-tag'>
                  {tag.name}
                </Link>
              ))}
            <Link to={`/blog/post/${post.node.slug}`}>
              <h2 className='post-title text-left light'>{post.node.title}</h2>
            </Link>
            {post.node.authors.map((author) => (
              <div className='f-author flex flex-row items-center'>
                <img
                  src={author.profile_image}
                  className='w-8 h-8 rounded-full'
                  alt='author dp'
                />
                <div className='authour-name ml-2'>{author.name}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeaturedBlogs;
