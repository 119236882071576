import {graphql} from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import Collections from "../createdpages/blog/Collections/Collections";
import FeaturedBlogs from "../createdpages/blog/FeaturedBlogs/FeaturedBlogs";
import NewPosts from "../createdpages/blog/NewPosts";

const BlogHome = ({data: cmsData}) => {
  const seoData = cmsData.allSanitySeoMeta.nodes[0];

  return (
    <Layout seoData={seoData}>
      {/* Structured data is inside <NewPosts /> as it contains the blog articles data */}
      <div className='full-bleed layouted bg-gray-200'>
        <FeaturedBlogs />
        <Collections />
        <NewPosts />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "blog"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
  }
`;

export default BlogHome;
