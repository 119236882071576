import {Link} from "gatsby";
import React from "react";
import ArrowRight from "react-feather/dist/icons/arrow-right";

import "./Collections.scss";

const Collections = () => {
  const data = [
    {
      color: "#E7544B",
      title: "Launch your store",
      tags: [
        {
          name: "📈 Sales",
          slug: "sales",
        },
        {
          name: "📢 Marketing",
          slug: "marketing",
        },
      ],
      description: "Get prepared to power-launch your store",
      slug: "launch",
    },
    {
      color: "#2C873B",
      title: "Get your first sale",
      tags: [
        {
          name: "📢 Marketing",
          slug: "marketing",
        },
      ],
      description: "Only upward and onward from your first sale.",
      slug: "firstsale",
    },
    {
      color: "#33A6A8",
      title: "Grow your business",
      tags: [
        {
          name: "📈 Sales",
          slug: "sales",
        },
      ],
      description: "Develop and expand your e-commerce business.",
      slug: "grow",
    },
    {
      color: "#8A3063",
      title: "Run Remote Teams",
      tags: [
        {
          name: "😄 Culture",
          slug: "culture",
        },
      ],
      description:
        "Notes on missteps, battle-scars and open learnings from our team.",
      slug: "team",
    },
    {
      color: "#30489E",
      title: "Love for Craft",
      tags: [
        {
          name: "🎨 Design",
          slug: "design",
        },
        {
          name: "💻 Technology",
          slug: "technology",
        },
      ],
      description: "From future of design to tips & advice on your store.",
      slug: "loveforcraft",
    },
    {
      color: "#FFC045",
      title: "In the News",
      tags: [
        {
          name: "🔊 Podcasts",
          slug: "podcasts",
        },
        {
          name: "🎥 Press",
          slug: "press",
        },
        // {
        //   name: "📜 News",
        //   slug: "podcasts",
        // },
        // {
        //   name: "🏅 Awards",
        //   slug: "press",
        // },
      ],
      description: "Catchup on the latest podcast updates & press coverage.",
      slug: "press",
    },
  ];
  return (
    <div className='collections-wrap grid grid-cols-1 px-4 md:px-0 md:grid-cols-3 gap-4 my-12'>
      {data.map((card) => (
        <div
          style={{borderColor: card.color}}
          className='collection-card shadow-md hover:shadow-xl p-4 rounded-lg'
        >
          <h3>{card.title}</h3>
          <div className='mr-2 my-4'>
            {card.tags.map((tag) => (
              <Link to={`/blog/tag/${tag.slug}`} className='post-tag mr-2 '>
                {tag.name}
              </Link>
            ))}
          </div>
          <p>{card.description}</p>
          <Link
            style={{color: card.color}}
            className='flex flex-row items-center mt-4'
            to={`/blog/${card.slug}`}
          >
            <h4 style={{color: card.color}} className='medium'>
              Read More
            </h4>
            &ensp;
            <ArrowRight />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Collections;
